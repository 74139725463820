import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero"

const AboutContentStyles = styled.section`
  padding: 5rem 0;
  line-height: 1.25;
  max-width: 800px;
  margin: 0 auto;
`

export default function AboutPage({ data, location }) {
  const siteTitle = "About Us"
  const page = data.file.childMarkdownRemark.frontmatter
  const content = data.file.childMarkdownRemark.html
  const config = page.configuration

  return (
    <Layout location={location} title={config?.title || siteTitle}>
       <SEO
        title={config?.title || siteTitle}
        keywords={config?.keywords || []}
      />
      <Hero
        Tag="section"
        image={page.hero.image}
        title={page.hero.title}>
        <div className="hero-content">
          <h1>{page.hero.title}</h1>
        </div>
      </Hero>
      <AboutContentStyles dangerouslySetInnerHTML={{ __html: content }} />
    </Layout>
  );
}

export const query = graphql`
query AboutPageQuery {
  file(sourceInstanceName: { eq: "pages" }, name: { eq: "about" }) {
    name
    childMarkdownRemark {
      id
      html
      frontmatter {
        templateKey
        configuration {
          title
          keywords
        }
        hero {
          title
          image {
            name
            childImageSharp {
              gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`;
